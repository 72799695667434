import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import SignInView from "@/views/SignInView.vue";
import SignUpView from "@/views/SignUpView.vue";
import OnboardingView from "@/views/OnboardingView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import OnboardingProfileInformation from "@/views/onboarding/ProfileInformationView.vue";
import OnboardingSoftwareExpertise from "@/views/onboarding/SoftwareExpertiseView.vue";
import OnboardingCalendar from "@/views/onboarding/CalendarView.vue";
import ExpertDashboard from "@/views/ExpertDashboardView.vue";
import ExpertExpertise from "@/views/ExpertExpertiseView.vue";
import ExpertEarnings from "@/views/ExpertEarningsView.vue";
import SignUpSelect from "@/views/SignUpSelectView.vue";
import SignInLanding from "@/views/SignInLandingView.vue";
import ExpertProfile from "@/views/ExpertProfileView.vue";
import ExpertProgramOverview from "@/views/ExpertProgramOverview.vue";
import { useAuthStore } from "@/stores/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/signinlanding",
    name: "SignInLanding",
    component: SignInLanding,
  },
  {
    path: "/",
    redirect: "/signin",
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignInView,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUpView,
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: OnboardingView,
    meta: { requiresAuth: true },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPasswordView,
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: ResetPasswordView,
  },
  {
    path: "/onboarding/profile_information",
    name: "OnboardingProfileInformation",
    component: OnboardingProfileInformation,
    meta: { requiresAuth: true },
  },
  {
    path: "/onboarding/software_expertise",
    name: "OnboardingSoftwareExpertise",
    component: OnboardingSoftwareExpertise,
    meta: { requiresAuth: true },
  },
  {
    path: "/onboarding/calendar",
    name: "OnboardingCalendar",
    component: OnboardingCalendar,
    meta: { requiresAuth: true },
  },
  {
    path: "/expert/dashboard",
    name: "ExpertDashboard",
    component: ExpertDashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/expert/expertise",
    name: "ExpertExpertise",
    component: ExpertExpertise,
    meta: { requiresAuth: true },
  },
  {
    path: "/expert/earnings",
    name: "ExpertEarnings",
    component: ExpertEarnings,
    meta: { requiresAuth: true },
  },
  {
    path: "/signupselect",
    name: "SignUpSelect",
    component: SignUpSelect,
  },
  {
    path: "/expert/profile",
    name: "ExpertProfile",
    component: ExpertProfile,
    meta: { requiresAuth: true },
  },
  {
    path: "/expert-program-overview",
    name: "ExpertProgramOverview",
    component: ExpertProgramOverview,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _, next) => {
  const authStore = useAuthStore();

  if (to.meta.requiresAuth && !authStore.isLoggedIn) {
    next("/signin?error=Please login.");
  } else {
    next();
  }
});

export default router;
