<template>
  <div
    class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
  >
    <!-- Spinner -->
    <div
      class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
    >
      <span class="text-white text-3xl font-semibold leading-9">
        Signing you in...
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { ProfileInformation } from "@/types/interfaces";
import { useRoute, useRouter } from "vue-router";

const GET_USER_PROFILE = gql`
  query userProfile($id: ID) {
    userProfile(id: $id) {
      firstName
      lastName
      email
      profile_picture
      uuid
    }
  }
`;

export default defineComponent({
  name: "SignInLanding",
  setup() {
    const authToken = ref<string | null>(null);
    const authStore = useAuthStore();
    const router = useRouter();
    const route = useRoute();
    const invitedToken = route.query.token;

    onMounted(() => {
      if (authStore.token) {
        console.log("Token found:", authStore.token);
        fetchUserProfile();
      } else {
        handleError("No token found.");
      }
    });

    const produktlyIdentifyUserLogin = (uuid: string, email: string) => {
      if (window.Produktly) {
        window.Produktly.identifyUser(uuid, {
          email: email,
        });
      }
    };

    const fetchUserProfile = () => {
      const { onResult, onError } = useQuery(GET_USER_PROFILE, {
        id: null,
      });

      onResult((result) => {
        if (result) {
          if (result.data?.userProfile) {
            let profileInformation: ProfileInformation = {
              linkedinUrl: "",
              country: "",
              state: "",
              industry: "",
              profilePicture: "",
              companyName: "",
              title: "",
            };
            produktlyIdentifyUserLogin(
              result.data.userProfile.uuid,
              result.data.userProfile.email
            );
            authStore.setFirstName(result.data.userProfile.firstName);
            authStore.setLastName(result.data.userProfile.lastName);
            authStore.setEmail(result.data.userProfile.email);
            profileInformation.profilePicture =
              result.data.userProfile.profile_picture;
            authStore.setProfileInformation(profileInformation);
            navigateToOnboarding();
          }
        }
      });

      onError((error) => {
        console.error(error);
        if (error) {
          handleError(error.message);
        }
      });
    };

    const checkInvitedProgram = () => {
      if (authStore.invitedProgram && !invitedToken) {
        authStore.clearInvitedProgram();
      }
    };

    const navigateToOnboarding = () => {
      if (authStore.token) {
        checkInvitedProgram();
        if (invitedToken) {
          router.push({
            name: "ExpertExpertise",
          });
        } else {
          router.push({ name: "ExpertDashboard" });
        }
      } else {
        handleError("Failed to set auth token");
      }
    };

    const handleError = (error: string) => {
      const landingUrl = `${
        process.env.VUE_APP_SIGN_IN_FORM_URL
      }?error=${encodeURIComponent(error)}`;
      window.open(landingUrl, "_self");
    };

    return {
      authToken,
    };
  },
});
</script>
