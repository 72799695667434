<template>
  <div class="flex flex-col gap-4 text-left">
    <div class="flex flex-col">
      <span
        class="text-black font-sans text-4xl font-medium leading-none tracking-tight"
        >FAQs</span
      >
    </div>
    <!-- faqs section -->
    <div class="flex flex-col gap-4">
      <!-- question one -->
      <div class="flex flex-col h-[1px] bg-black"></div>
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >What is SlashExperts?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="firstAnswerVisible = !firstAnswerVisible"
          ><span v-if="!firstAnswerVisible">+</span><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="firstAnswerVisible">
        <span
          class="text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
          >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </span>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
      <!-- question two -->
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >What does being an expert mean?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="secondAnswerVisible = !secondAnswerVisible"
          ><span v-if="!secondAnswerVisible">+</span><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="secondAnswerVisible">
        <span
          class="text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
          >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </span>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
      <!-- question three -->
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >How do I become an expert?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="thirdAnswerVisible = !thirdAnswerVisible"
          ><span v-if="!thirdAnswerVisible">+</span><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="thirdAnswerVisible">
        <span
          class="text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
          >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </span>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
      <!-- question four -->
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >How much can I earn from being an expert?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="fourthAnswerVisible = !fourthAnswerVisible"
          ><span v-if="!fourthAnswerVisible">+</span><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="fourthAnswerVisible">
        <span
          class="text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
          >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </span>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
      <!-- question five -->
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >How long is an average conversation?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="fifthAnswerVisible = !fifthAnswerVisible"
          ><span v-if="!fifthAnswerVisible">+</span><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="fifthAnswerVisible">
        <span
          class="text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
          >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </span>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
      <!-- question six -->
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >Will I be able to control my availability?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="sixthAnswerVisible = !sixthAnswerVisible"
          ><span v-if="!sixthAnswerVisible">+</span><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="sixthAnswerVisible">
        <span
          class="text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
          >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </span>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
      <!-- question seven -->
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >Where can I find your Help Center with even more detailed FAQs and
          helpful resources?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="seventhAnswerVisible = !seventhAnswerVisible"
          ><span v-if="!seventhAnswerVisible">+</span
          ><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="seventhAnswerVisible">
        <span
          class="text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
          >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </span>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "SectionSix",
  setup() {
    const firstAnswerVisible = ref(false);
    const secondAnswerVisible = ref(false);
    const thirdAnswerVisible = ref(false);
    const fourthAnswerVisible = ref(false);
    const fifthAnswerVisible = ref(false);
    const sixthAnswerVisible = ref(false);
    const seventhAnswerVisible = ref(false);

    return {
      firstAnswerVisible,
      secondAnswerVisible,
      thirdAnswerVisible,
      fourthAnswerVisible,
      fifthAnswerVisible,
      sixthAnswerVisible,
      seventhAnswerVisible,
    };
  },
});
</script>
