<template>
  <div class="flex flex-col px-16" :style="{ backgroundColor: primaryColor }">
    <div class="flex flex-col md:flex-row justify-between">
      <!-- left section -->
      <div
        class="flex flex-col py-16 gap-4 text-center items-center md:items-start md:text-left"
      >
        <div class="flex flex-col">
          <span class="text-white font-sans text-2xl font-medium leading-none">
            Ready to Get Started?
          </span>
        </div>
        <div class="flex flex-col">
          <span
            class="text-white font-sans text-base font-normal leading-none tracking-wide"
            >Joining SlashExperts is quick and easy. Your expertise has value
            let’s share it with the world.</span
          >
        </div>
        <div class="flex flex-col">
          <BaseButton
            type="button"
            @click="joinExpertProgram()"
            class="px-4 py-2 max-w-max text-xs sm:text-sm font-medium leading-none text-black border border-gray-300 rounded-lg bg-white"
          >
            Become An Expert Now
          </BaseButton>
        </div>
      </div>
      <!-- right section -->
      <div class="flex flex-col items-center md:items-start">
        <img
          src="@/assets/expert-program/overview/section-seven/expert-cards.svg"
          class="w-2/3 sm:w-1/2 md:w-full h-full"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";

export default defineComponent({
  name: "SectionSeven",
  components: {
    BaseButton,
  },
  props: {
    primaryColor: {
      type: String,
      required: true,
    },
    joinExpertProgram: {
      type: Function,
      required: true,
    },
  },
});
</script>
