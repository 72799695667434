<template>
  <div class="flex flex-col">
    <div class="flex flex-col sm:flex-row justify-between">
      <!-- left section -->
      <div class="flex flex-col flex-1 gap-2 w-full sm:w-1/2 text-left">
        <div class="flex flex-col w-3/4">
          <span class="text-black font-sans text-6xl font-medium leading-none">
            Create Value, Earn Rewards
          </span>
        </div>
        <div class="flex flex-col w-3/4">
          <span
            class="text-gray-500 font-sans text-base font-normal leading-none tracking-wide"
            >Turn your expertise into income by helping others make informed
            decisions. Share your knowledge, work on your terms.</span
          >
        </div>
        <div class="flex flex-col">
          <BaseButton
            type="button"
            @click="joinExpertProgram()"
            class="px-4 py-2 max-w-max text-xs sm:text-sm font-medium leading-none text-white border border-gray-300 rounded-full"
            :style="{ backgroundColor: primaryColor }"
          >
            Join Now
          </BaseButton>
        </div>
      </div>

      <!-- right section -->
      <div class="flex flex-col flex-1 w-full sm:w-1/2">
        <div class="flex flex-row gap-2">
          <!-- inner left section -->
          <div class="flex flex-col justify-center relative">
            <!-- Background SVG -->
            <div
              v-html="LEFT_TILTED_SQUARE"
              class="absolute items-center left-0 w-[100%] h-[100%] z-0"
              :style="{ color: primaryColor }"
            ></div>

            <!-- Foreground Image -->
            <div
              class="flex flex-col relative z-10 items-center justify-center"
            >
              <img
                src="@/assets/expert-program/overview/section-one/profile-picture-large-2.svg"
                alt=""
                class="w-full p-2 sm:p-4 rounded-lg object-cover"
              />

              <!-- Image in the middle of the foreground image -->
              <div
                class="absolute bottom-0 right-0 transform translate-x-1/2 -translate-y-3/4 z-20"
              >
                <div
                  class="bg-white rounded-full px-2 py-1 text-black font-sans text-base font-medium leading-none"
                >
                  <span
                    class="whitespace-nowrap text-4xs sm:text-sm md:text-base lg:text-lg"
                  >
                    <span class="text-lime-600">$</span> Earn Money
                  </span>
                </div>
              </div>

              <!-- Text at the bottom of the foreground image -->
              <div
                class="absolute bottom-0 left-0 pl-4 pb-1 transform translate-x-1/5 translate-y-1/2 z-20"
              >
                <div
                  class="flex flex-row gap-1 items-center justify-center bg-white rounded-full px-2 py-1 text-black font-sans text-base font-medium leading-none"
                >
                  <div v-html="CHECK_MARK" class="w-4 h-4 text-lime-600"></div>
                  <span
                    class="whitespace-nowrap text-4xs sm:text-2xs md:text-sm lg:text-base"
                  >
                    Trusted by 10,000+ experts worldwide</span
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- inner right section -->
          <div class="flex flex-col gap-2">
            <div class="relative flex flex-col">
              <!-- Large Profile Picture -->
              <div class="relative">
                <img
                  src="@/assets/expert-program/overview/section-one/profile-picture-large.svg"
                  alt=""
                  class="rounded-lg object-cover w-full"
                />

                <!-- Small Profile Picture with Circular Border and Padding (Top-Left) -->
                <div
                  class="absolute top-0 left-0 flex items-center justify-center w-2/3 sm:w-1/2 lg:w-1/3 border-[0.5px] rounded-full p-4"
                  style="transform: translate(-40%, -40%)"
                  :style="{ borderColor: primaryColor }"
                >
                  <img
                    src="@/assets/expert-program/overview/section-one/profile-picture-small.svg"
                    alt=""
                    class="rounded-full object-cover w-full h-full"
                  />
                </div>

                <!-- Icon (Center-Bottom) -->
                <div
                  v-html="VIDEO_CAMERA_ICON"
                  class="absolute h-12 w-12 bottom-4 left-1/2 transform -translate-x-1/2 translate-y-0 bg-white p-2 rounded-full shadow-md"
                  :style="{ color: primaryColor }"
                ></div>
              </div>
            </div>

            <div class="flex flex-col">
              <img
                src="@/assets/expert-program/overview/section-one/ellipse.svg"
                alt=""
                class="w-full bg-gray-200 rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import {
  VIDEO_CAMERA_ICON,
  CHECK_MARK,
} from "@/assets/svg/shared/svgConstants";
import { LEFT_TILTED_SQUARE } from "@/assets/svg/expert-program-overview/svgConstants";

export default defineComponent({
  name: "SectionOne",
  components: {
    BaseButton,
  },
  props: {
    primaryColor: {
      type: String,
      required: true,
    },
    joinExpertProgram: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return {
      LEFT_TILTED_SQUARE,
      VIDEO_CAMERA_ICON,
      CHECK_MARK,
    };
  },
});
</script>
