<template>
  <div class="flex flex-col gap-4 text-left">
    <div class="flex flex-col">
      <span
        class="text-black font-sans text-4xl font-medium leading-none tracking-tight"
        >Our Experts Love What They Do</span
      >
    </div>
    <div class="flex flex-col sm:flex-row justify-between gap-4">
      <div class="flex flex-col flex-1 gap-4">
        <div class="flex flex-col">
          <img
            src="@/assets/expert-program/overview/section-five/profile-one.svg"
          />
        </div>
        <div class="flex flex-row justify-center items-center gap-2">
          <div class="flex flex-col justify-center">
            <img src="@/assets/shared/yellow-waves.svg" class="w-full" />
          </div>
          <div class="flex flex-col gap-2">
            <span
              class="text-gray-500 font-sans text-base font-medium leading-none"
              >Sophia</span
            >
            <span
              class="text-gray-800 font-sans text-base font-normal leading-none"
              >Being a Marketo Expert has been amazing! I get to share what I
              know and make money at the same time.</span
            >
          </div>
        </div>
      </div>
      <div class="flex flex-col flex-1 gap-4">
        <div class="flex flex-col">
          <img
            src="@/assets/expert-program/overview/section-five/profile-two.svg"
          />
        </div>
        <div class="flex flex-row justify-center items-center gap-2">
          <div class="flex flex-col justify-center">
            <img src="@/assets/shared/yellow-waves.svg" class="w-full" />
          </div>
          <div class="flex flex-col gap-2">
            <span
              class="text-gray-500 font-sans text-base font-medium leading-none"
              >Ethan</span
            >
            <span
              class="text-gray-800 font-sans text-base font-normal leading-none"
              >This platform has helped me grow my network and earn extra income
              effortlessly.</span
            >
          </div>
        </div>
      </div>
      <div class="flex flex-col flex-1 gap-4">
        <div class="flex flex-col">
          <img
            src="@/assets/expert-program/overview/section-five/profile-three.svg"
          />
        </div>
        <div class="flex flex-row justify-center items-center gap-2">
          <div class="flex flex-col justify-center">
            <img src="@/assets/shared/yellow-waves.svg" class="w-full" />
          </div>
          <div class="flex flex-col gap-2">
            <span
              class="text-gray-500 font-sans text-base font-medium leading-none"
              >Amelia</span
            >
            <span
              class="text-gray-800 font-sans text-base font-normal leading-none"
              >This community helped me grow and connect with amazing
              professionals.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
