<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
  >
    <div
      class="bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2 p-4 flex flex-col"
    >
      <div class="flex justify-between items-center">
        <div></div>
        <!-- Empty div to push the button to the right -->
        <button class="text-gray-600 hover:text-gray-800" @click="closeModal">
          <div v-html="CLOSE_ICON"></div>
        </button>
      </div>
      <div
        v-if="errorMessage"
        role="alert"
        class="flex gap-2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
        style="max-height: 80px; overflow-y: auto"
      >
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <img
            src="../../../assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ errorMessage }}</p>
      </div>
      <!-- Flex container for vertically and horizontally centering loading/error text -->
      <div class="flex flex-col gap-4 justify-start items-center w-full">
        <div class="text-gray-600 font-semibold text-center text-lg">
          <span>Payment Method Details</span>
        </div>
        <div class="flex flex-col text-gray-600 text-center text-lg">
          <span v-for="(value, key) in payoutMethodDetails" :key="key">
            {{ key }}: {{ value }}
          </span>
        </div>
        <div class="flex flex-col items-center gap-2">
          <BaseLabel
            forAttr="amount"
            class="text-gray-600 font-semibold text-center text-lg"
            :class="{ 'text-red-500': amountError }"
            :text="`Withdraw Upto $${availableBalance}`"
          />
          <input
            id="amount"
            type="number"
            :value="amount"
            @input="handleInput"
            placeholder="Enter amount"
            class="p-2 border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap focus:outline-none"
            :class="{ 'border-red-500': amountError }"
            min="0"
            step="1"
            :disabled="availableBalance === undefined || availableBalance < 0"
          />
        </div>
        <div class="flex flex-col">
          <BaseButton
            type="button"
            :disabled="isLoading"
            @click="withdrawBalance"
            class="overflow-hidden gap-3 self-stretch py-2.5 pr-4 pl-4 mt-4 w-full text-base font-medium text-indigo-50 bg-primary-purple rounded-md shadow-sm"
          >
            <span v-if="!isLoading"> Withdraw </span>
            <span v-else> Withdrawing... </span>
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { CLOSE_ICON } from "@/assets/svg/shared/svgConstants";
import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
const WITHDRAW_FUNDS = gql`
  mutation withdrawFunds($input: WithdrawFundsInput!) {
    withdrawFunds(input: $input) {
      success
      message
    }
  }
`;

export default defineComponent({
  name: "WithdrawModal",
  components: {
    BaseButton,
    BaseLabel,
  },
  props: {
    iframeSrc: String,
    isVisible: Boolean,
    payoutMethodDetails: Object,
    availableBalance: Number,
  },
  emits: ["update:amountWithdrawn", "update:modalClose"],
  setup(props, { emit }) {
    const isLoading = ref(false);
    const hasError = ref(false);
    const errorMessage = ref("");
    const amount = ref<number | null>(null);
    const amountError = ref(false);

    const {
      mutate: withdrawAmount,
      onDone,
      onError,
    } = useMutation(WITHDRAW_FUNDS);

    const closeModal = () => {
      emit("update:modalClose");
    };

    const withdrawBalance = () => {
      if (validateWithdrawAmount()) {
        handleWithdraw();
      } else {
        handleError("Amount must be greater than 0");
      }
    };

    const handleError = (message: string) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 2500);
    };

    const handleWithdraw = () => {
      isLoading.value = true;
      withdrawAmount({ input: { amount: amount.value } });

      onDone((response) => {
        if (response) {
          if (response.data.withdrawFunds.success) {
            emit("update:amountWithdrawn");
          } else {
            handleError(response.data.withdrawFunds.message);
          }
          isLoading.value = false;
        }
      });

      onError((error) => {
        if (error) {
          handleError(error.message);
          isLoading.value = false;
        }
      });
    };

    const showAmountError = () => {
      amountError.value = true;
      setTimeout(() => {
        amountError.value = false;
      }, 1000);
    };

    const handleInput = (event: Event) => {
      if (props.availableBalance === undefined || props.availableBalance < 0) {
        return;
      }
      const inputElement = event.target as HTMLInputElement;
      let numericValue = parseFloat(inputElement.value);

      if (inputElement.value === "") {
        amount.value = null;
        return;
      }

      if (!isNaN(numericValue)) {
        const [integerPart, decimalPart] = inputElement.value.split(".");
        if (decimalPart?.length > 2) {
          numericValue = parseFloat(
            `${integerPart}.${decimalPart.slice(0, 2)}`
          );
          inputElement.value = numericValue?.toFixed(2);
        }
        console.log("available balance", props.availableBalance);

        if (numericValue > props.availableBalance) {
          inputElement.value = amount.value?.toString() || "";
          showAmountError();
          return;
        }

        amount.value = numericValue;
      } else {
        inputElement.value = amount.value?.toString() || "";
      }
    };

    const validateWithdrawAmount = () => {
      return amount.value && amount.value > 0;
    };

    return {
      closeModal,
      CLOSE_ICON,
      isLoading,
      hasError,
      withdrawBalance,
      amount,
      errorMessage,
      handleInput,
      amountError,
    };
  },
});
</script>
