<template>
  <article class="flex flex-col w-[32%] max-md:ml-0 max-md:w-full">
    <div
      class="flex flex-col grow justify-start p-6 w-full bg-white rounded border border-gray-300 border-solid max-md:px-5 max-md:mt-6 text-left"
    >
      <div class="flex flex-col w-full">
        <div class="flex flex-col w-full">
          <div class="flex gap-5 items-center w-full">
            <div
              class="flex flex-col flex-1 shrink self-stretch my-auto w-full basis-0"
            >
              <div class="flex flex-row gap-2">
                <div
                  class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
                >
                  <div v-html="BANK_ICON"></div>
                </div>
                <div class="flex flex-col w-full">
                  <h2
                    class="gap-2 max-w-full text-md font-semibold leading-none text-gray-900"
                  >
                    Available Balance
                  </h2>
                  <p
                    class="text-md font-medium leading-none text-gray-700 mt-1"
                  >
                    ${{ availableBalance?.toFixed(2) || 0 }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col justify-center p-2.5 mt-5 w-full rounded-lg"
          >
            <div class="flex gap-4 items-center w-full">
              <div
                class="flex flex-col flex-1 shrink justify-center self-stretch my-auto font-medium basis-6"
              >
                <div
                  v-if="dotsOnboarded === 'completed'"
                  class="flex flex-col flex-1 shrink w-full basis-0"
                >
                  <h3 class="text-base font-medium text-gray-900">
                    Your account is synced with:
                  </h3>
                  <div class="w-full">
                    <div
                      v-for="(value, key) in payoutMethodDetails"
                      :key="key"
                      class="text-sm font-medium bg-gray-100 px-2 py-1 rounded-md mb-1 mr-2 break-all"
                    >
                      {{ key }}: {{ value }}
                    </div>
                  </div>
                </div>
                <div v-else class="flex gap-2 items-center">
                  <div
                    v-if="!dotsFlowId"
                    v-html="ALERT_ICON"
                    class="text-gray-500"
                  ></div>
                  <div
                    v-if="dotsFlowId && dotsOnboarded === 'pending'"
                    v-html="CLOCK_ICON"
                    class="text-gray-500"
                  ></div>
                  <p class="text-sm leading-none text-gray-500">
                    {{ payoutText }}
                  </p>
                </div>
                <div
                  v-if="dotsFlowId && dotsOnboarded === 'pending'"
                  class="mt-2"
                >
                  <p
                    class="text-sm leading-none px-1 py-2 bg-yellow-50 text-amber-700 rounded-md"
                  >
                    It may take up to 24 hours, please check back later.
                  </p>
                </div>
                <div
                  v-if="dotsFlowId && dotsOnboarded === 'failed'"
                  class="mt-2 flex bg-red-50 gap-2 px-1 py-2 text-red-900 rounded-md items-center"
                >
                  <div v-html="ALERT_ICON"></div>
                  <p class="text-sm leading-none">
                    Failed to onboard, please try again.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BaseButton
          type="button"
          :disabled="
            isLoading ||
            (dotsOnboarded === 'completed' && availableBalance <= 0)
          "
          @click="withdrawBalance"
          class="overflow-hidden gap-3 self-stretch py-2.5 pr-4 pl-4 mt-4 w-full text-base font-medium text-indigo-700 bg-indigo-50 rounded-md shadow-sm"
          :class="{
            'bg-gray-300 opacity-50':
              isLoading ||
              (dotsOnboarded === 'completed' && availableBalance <= 0),
          }"
        >
          <span v-if="isLoading"> Processing... </span>
          <span v-else> Withdraw Balance </span>
        </BaseButton>
      </div>
    </div>
  </article>
  <WithdrawModal
    :isVisible="isModalVisible"
    :availableBalance="availableBalance"
    :payoutMethodDetails="payoutMethodDetails || {}"
    @update:amountWithdrawn="handleWithdrawal"
    @update:modalClose="handleModalClose"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { BANK_ICON } from "@/assets/svg/expert-overview/svgConstants";
import BaseButton from "@/components/shared/BaseButton.vue";
import WithdrawModal from "./WithdrawModal.vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { ALERT_ICON, CLOCK_ICON } from "@/assets/svg/shared/svgConstants";

const GET_USER_PROFILE = gql`
  query userProfile($id: ID) {
    userProfile(id: $id) {
      dotsFlowId
      dotsUserId
      payoutMethodDetails
      credits
      dotsOnboardingStatus
    }
  }
`;

const GET_ONBOARDING_URL = gql`
  query dotsOnboarding {
    dotsOnboarding {
      url
    }
  }
`;

export default defineComponent({
  name: "TransferToAccount",
  components: {
    BaseButton,
    WithdrawModal,
  },
  props: {
    handleError: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const isModalVisible = ref(false);
    const payoutMethodDetails = ref(null);
    const dotsFlowId = ref(false);
    const dotsUserId = ref(null);
    const dotsOnboarded = ref("");
    const availableBalance = ref(0.0);
    const payoutText = ref(
      "Click Withdraw to transfer your funds. You'll create a secure account with our payment partner Dots.dev to receive your money. This is a one-time setup that takes about 2 minutes."
    );
    const isLoading = ref<boolean>(false);

    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    const openModal = () => {
      isModalVisible.value = true;
    };

    const withdrawBalance = () => {
      if (dotsOnboarded.value === "completed") {
        openModal();
      } else {
        onboardExpert();
      }
    };

    const onboardExpert = () => {
      isLoading.value = true;

      const { onResult, onError } = useQuery(GET_ONBOARDING_URL, {
        fetchPolicy: "network-only",
      });

      onResult((result) => {
        if (result) {
          if (result.data?.dotsOnboarding) {
            if (result.data.dotsOnboarding.url) {
              window.location.href = result.data.dotsOnboarding.url;
            } else {
              props.handleError("No onboarding url found");
            }
          }
          isLoading.value = false;
        }
      });

      onError((error) => {
        if (error) {
          props.handleError("Something went wrong.");
          isLoading.value = false;
          scrollToTop();
        }
      });
    };

    const handleModalClose = () => {
      isModalVisible.value = false;
    };

    onMounted(() => {
      fetchEarningsDetails();
    });

    const fetchEarningsDetails = () => {
      const { onResult, onError } = useQuery(
        GET_USER_PROFILE,
        {
          id: null,
        },
        {
          fetchPolicy: "network-only",
        }
      );

      onResult((result) => {
        if (result.errors) {
          props.handleError(
            "Failed to fetch necessary data, please refresh page to try again."
          );
        } else if (result.data?.userProfile) {
          payoutMethodDetails.value =
            result.data.userProfile.payoutMethodDetails;
          dotsFlowId.value = result.data.userProfile.dotsFlowId;
          dotsUserId.value = result.data.userProfile.dotsUserId;
          availableBalance.value = result.data.userProfile.credits;
          dotsOnboarded.value = result.data.userProfile.dotsOnboardingStatus;
          setPayoutText();
        }
      });

      onError((error) => {
        if (error) {
          props.handleError(
            "Failed to fetch necessary data, please refresh page to try again."
          );
          scrollToTop();
        }
      });
    };

    const setPayoutText = () => {
      if (!dotsFlowId.value) {
        payoutText.value =
          "Click Withdraw to transfer your funds. You'll create a secure account with our payment partner Dots.dev to receive your money. This is a one-time setup that takes about 2 minutes.";
      } else {
        if (dotsOnboarded.value === "pending") {
          payoutText.value = "Waiting on dots for confirmation.";
        } else if (dotsOnboarded.value === "failed") {
          payoutText.value =
            "Your onboarding is incomplete, please complete it to start withdrawing your money.";
        }
      }
    };

    const handleWithdrawal = () => {
      isModalVisible.value = false;
      fetchEarningsDetails();
    };

    return {
      BANK_ICON,
      ALERT_ICON,
      CLOCK_ICON,
      openModal,
      isModalVisible,
      availableBalance,
      payoutMethodDetails,
      dotsFlowId,
      dotsUserId,
      withdrawBalance,
      isLoading,
      handleWithdrawal,
      handleModalClose,
      payoutText,
      dotsOnboarded,
    };
  },
});
</script>
