<template>
  <div class="flex overflow-hidden flex-col pb-60 bg-white max-md:pb-24">
    <div
      v-if="isFetchingUserProfile"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-999"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <header class="flex flex-col w-full max-md:max-w-full">
      <ExpertiseNavbar :activeTab="-1" :key="navbarKey" />
    </header>
    <main
      class="flex flex-col px-10 mt-10 w-full max-md:px-5 max-md:max-w-full"
    >
      <div class="flex flex-col ml-4">
        <div class="flex flex-wrap justify-between items-center">
          <div class="flex flex-col gap-4">
            <span class="text-black text-3xl font-semibold leading-9 text-left"
              >Profile Information</span
            >
          </div>
          <div class="flex flex-col" v-if="!viewUpdateFields">
            <div class="flex flex-col text-left">
              <BaseButton
                type="button"
                @click="handleUpdate"
                class="flex flex-col justify-center items-center p-3 w-full text-white bg-primary-purple rounded-md text-sm font-medium leading-none text-center"
              >
                Update
              </BaseButton>
            </div>
          </div>
          <div v-else class="flex flex-wrap gap-4 items-center">
            <div v-if="updateUserProfileLoading" class="flex flex-col">
              <span class="text-primary-purple">Saving...</span>
            </div>
            <div class="flex flex-col">
              <BaseButton
                type="button"
                @click="handleCancel"
                class="flex flex-col justify-center items-center p-3 w-full text-black border border-gray-300 rounded-md text-sm font-medium leading-none text-center"
              >
                Cancel
              </BaseButton>
            </div>
            <div class="flex flex-col">
              <BaseButton
                type="button"
                @click="handleSave"
                class="flex flex-col justify-center items-center p-3 w-full text-white bg-primary-purple rounded-md text-sm font-medium leading-none text-center"
              >
                Save
              </BaseButton>
            </div>
          </div>
        </div>
        <div class="flex justify-center mb-4">
          <div
            v-if="errorMessage"
            role="alert"
            class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
            style="max-height: 80px; overflow-y: auto"
          >
            <!-- Icon Container -->
            <div
              class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
            >
              <!-- Custom Image Icon -->
              <img
                src="@/assets/shared/failure.svg"
                alt="Error Icon"
                class="w-4 h-4"
              />
            </div>
            <p>{{ errorMessage }}</p>
          </div>
        </div>
        <div v-if="!viewUpdateFields" class="flex flex-col">
          <CurrentDetails
            :firstName="firstName"
            :lastName="lastName"
            :email="email"
            :profilePicture="profilePicture"
            :linkedinProfile="linkedinProfile"
            :title="title"
            :companyName="companyName"
          />
        </div>
        <div v-else>
          <UpdateDetails
            :firstName="firstName"
            :lastName="lastName"
            :email="email"
            :profilePicture="profilePicture"
            :linkedinProfile="linkedinProfile"
            :title="title"
            :companyName="companyName"
            @update:firstName="firstName = $event"
            @update:lastName="lastName = $event"
            @update:email="email = $event"
            @update:profilePicture="profilePicture = $event"
            @update:linkedinProfile="linkedinProfile = $event"
            @update:title="title = $event"
            @update:companyName="companyName = $event"
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import BaseButton from "@/components/shared/BaseButton.vue";
import CurrentDetails from "@/components/expert-profile/CurrentDetails.vue";
import { defineComponent, onMounted, ref, getCurrentInstance } from "vue";
import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import UpdateDetails from "@/components/expert-profile/UpdateDetails.vue";
import ExpertiseNavbar from "@/components/expertise/ExpertiseNavbar.vue";
import { User } from "@/types/interfaces";
import { useAuthStore } from "@/stores/auth";
import { PostHog } from "posthog-js";

const GET_USER_PROFILE = gql`
  query userProfile($id: ID) {
    userProfile(id: $id) {
      firstName
      lastName
      email
      profile_picture
      linkedin_profile
      title
      companyName
      uuid
    }
  }
`;

const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation updateUserProfile($input: UpdateInput!) {
    updateUserProfile(input: $input) {
      updateUserResponse {
        user {
          profile_picture
        }
        errors
      }
    }
  }
`;

export default defineComponent({
  name: "AccountContainer",
  components: {
    BaseButton,
    CurrentDetails,
    UpdateDetails,
    ExpertiseNavbar,
  },
  setup() {
    // const userStore = useUserStore();
    const profilePicture = ref("");
    const firstName = ref("");
    const lastName = ref("");
    const email = ref("");
    const uuid = ref("");
    const linkedinProfile = ref("");
    const viewUpdateFields = ref(false);
    const errorMessage = ref("");
    const title = ref("");
    const companyName = ref("");
    const authStore = useAuthStore();
    const oldState = ref<User | null>(null);
    const navbarKey = ref(Date.now());
    const isFetchingUserProfile = ref(false);
    const posthog = (getCurrentInstance()?.proxy as { $posthog: PostHog })
      ?.$posthog;

    onMounted(() => {
      fetchUserProfile();
    });

    const {
      mutate: updateUserProfile,
      onDone,
      onError,
      loading: updateUserProfileLoading,
    } = useMutation(UPDATE_USER_PROFILE_MUTATION);

    const handleUser = (user: User) => {
      profilePicture.value = user.profile_picture || "";
      firstName.value = user.firstName;
      lastName.value = user.lastName;
      email.value = user.email;
      uuid.value = user.uuid;
      linkedinProfile.value = user.linkedin_profile;
      title.value = user.title;
      companyName.value = user.companyName;
      authStore.setFirstName(user.firstName);
      authStore.setLastName(user.lastName);
      authStore.setEmail(user.email);
      authStore.updateProfilePicture(profilePicture.value);

      oldState.value = user;
    };

    const handleUpdate = () => {
      viewUpdateFields.value = true;
    };

    const handleCancel = () => {
      viewUpdateFields.value = false;
      reloadOldState();
    };

    const reloadOldState = () => {
      if (oldState.value) {
        handleUser(oldState.value);
      }
    };

    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    const handleSave = () => {
      const isValid = validateFields();
      if (isValid) {
        updateUserProfile({
          input: {
            profileParams: {
              firstName: firstName.value,
              lastName: lastName.value,
              email: email.value,
              profilePicture: profilePicture.value,
              linkedinProfile: linkedinProfile.value,
              title: title.value,
              companyName: companyName.value,
            },
          },
        });

        onDone((result) => {
          if (result) {
            if (result.errors) {
              handleError("Something went wrong.");
            } else {
              const response =
                result.data?.updateUserProfile?.updateUserResponse;
              if (response) {
                if (response.errors) {
                  handleError("Something went wrong.");
                } else {
                  posthog.identify(uuid.value, {
                    email: email.value,
                    expert_first: firstName.value,
                    expert_last: lastName.value,
                    expert_email: email.value,
                  });
                  viewUpdateFields.value = false;
                  fetchUserProfile();
                  navbarKey.value = Date.now();
                }
              }
            }
          }
        });

        onError((error) => {
          console.error(error);
          if (error) {
            handleError("Something went wrong.");
          }
        });
      }
    };

    const validateFields = () => {
      const errors = [];

      if (!firstName.value) {
        errors.push("First name cannot be empty.");
      }
      if (!lastName.value) {
        errors.push("Last name cannot be empty.");
      }
      if (!email.value) {
        errors.push("Email cannot be empty.");
      }
      if (!linkedinProfile.value) {
        errors.push("Linkedin profile cannot be empty.");
      }
      if (!title.value) {
        errors.push("Title cannot be empty.");
      }
      if (!companyName.value) {
        errors.push("Company name cannot be empty.");
      }
      if (!profilePicture.value) {
        errors.push("Profile picture cannot be empty.");
      }
      if (!validEmail(email.value)) {
        errors.push("Invalid email format.");
      }
      if (title.value.length > 100) {
        errors.push("Title cannot be longer than 100 characters.");
      }
      if (companyName.value.length > 100) {
        errors.push("Company name cannot be longer than 100 characters.");
      }
      validateLinkedinProfile(errors);

      if (errors.length === 0) {
        return true;
      } else {
        handleError(errors.join(". "));
        return false;
      }
    };

    const validateLinkedinProfile = (errors: string[]): string[] => {
      try {
        let url = linkedinProfile.value;
        if (!url.toLowerCase().includes("linkedin.com/in/")) {
          errors.push("Linkedin URL missing linkedin.com/in/");
          return errors;
        }

        url = url.endsWith("/") ? url.slice(0, -1) : url;
        const parts = url.split("/in/");
        if (parts.length > 1 && /^[a-zA-Z0-9-]+$/.test(parts[1])) {
          return errors;
        } else {
          errors.push("Invalid LinkedIn profile username.");
          return errors;
        }
      } catch (error) {
        errors.push("Invalid LinkedIn URL format.");
        return errors;
      }
    };

    const validEmail = (email: string) => {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      return pattern.test(email);
    };

    const fetchUserProfile = () => {
      isFetchingUserProfile.value = true;
      const { onResult, onError } = useQuery(
        GET_USER_PROFILE,
        {
          id: null,
        },
        {
          fetchPolicy: "network-only",
        }
      );

      onResult((result) => {
        if (result) {
          if (result.data?.userProfile) {
            handleUser(result.data.userProfile);
          }
          isFetchingUserProfile.value = false;
        }
      });

      onError((error) => {
        console.error(error);
        if (error) {
          handleError(
            "Failed to fetch necessary data, please refresh page to try again."
          );
          isFetchingUserProfile.value = false;
        }
      });
    };

    const handleError = (error: string) => {
      scrollToTop();
      errorMessage.value = error;
      setTimeout(() => {
        errorMessage.value = "";
      }, 3000);
    };

    return {
      profilePicture,
      firstName,
      lastName,
      email,
      uuid,
      title,
      companyName,
      handleUpdate,
      handleCancel,
      viewUpdateFields,
      handleSave,
      errorMessage,
      updateUserProfileLoading,
      navbarKey,
      isFetchingUserProfile,
      linkedinProfile,
    };
  },
});
</script>
