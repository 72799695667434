<template>
  <div class="flex flex-col">
    <div class="relative flex flex-col">
      <img
        src="@/assets/expert-program/overview/section-three/background.svg"
        class="w-full"
      />

      <div
        class="absolute flex w-full bottom-0 translate-y-1/2 justify-between px-12"
      >
        <BlockOne :primary-color="primaryColor" class="w-1/4" />
        <BlockTwo :primary-color="primaryColor" class="w-1/4" />
        <BlockThree :primary-color="primaryColor" class="w-1/4" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BlockOne from "./assets/section-three/BlockOne.vue";
import BlockTwo from "./assets/section-three/BlockTwo.vue";
import BlockThree from "./assets/section-three/BlockThree.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SectionThree",
  components: {
    BlockOne,
    BlockTwo,
    BlockThree,
  },
  props: {
    primaryColor: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
</script>
