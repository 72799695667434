<template>
  <main class="flex flex-col min-h-screen bg-gray-50">
    <section
      class="flex-grow relative flex flex-col justify-center items-center px-6 w-full max-md:px-5 max-md:max-w-full"
    >
      <img
        loading="lazy"
        src="../assets/shared/background.png"
        alt=""
        class="absolute inset-0 w-full h-full object-cover"
      />
      <div class="flex relative flex-col mb-5 max-w-full">
        <img
          loading="lazy"
          src="../assets/shared/company-logo.svg"
          alt="Company logo"
          class="self-center max-w-full aspect-[4]"
        />
        <form
          @submit.prevent="handleSubmit"
          class="flex flex-col justify-center px-6 py-8 mt-16 w-full bg-white rounded-md border border-solid shadow-sm max-md:px-5 max-md:mt-10"
        >
          <h1
            class="text-3xl font-semibold leading-9 text-center text-zinc-800"
          >
            Log in to your account
          </h1>
          <div>
            <!-- Error Message Section -->
            <div
              v-if="errorMessage"
              role="alert"
              class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
              style="max-height: 80px; overflow-y: auto"
            >
              <!-- Icon Container -->
              <div
                class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
              >
                <!-- Custom Image Icon -->
                <img
                  src="../assets/shared/failure.svg"
                  alt="Error Icon"
                  class="w-4 h-4"
                />
              </div>
              <p>{{ errorMessage.slice(0, 60) }}</p>
            </div>
            <!-- Default Message Section -->
            <p
              v-else
              class="mt-3 text-base leading-6 text-center text-neutral-700"
            >
              Welcome back! Please enter your details
            </p>
          </div>

          <BaseLabel
            forAttr="email"
            text="Email"
            class="mt-6 font-medium text-zinc-800 text-left"
          />
          <div class="relative">
            <BaseInput
              type="email"
              id="email"
              v-model="email"
              placeholder="you@example.com"
              required
              className="px-3.5 py-2.5 mt-1 text-gray-500 bg-white rounded-md border border-solid shadow-sm w-full"
            />
            <i
              v-if="isInvalid"
              class="fas fa-exclamation-circle absolute right-3 top-3 text-red-500 top-1/2 transform -translate-y-1/2"
            ></i>
          </div>
          <p v-if="isInvalid" class="mt-2 text-gray-500 text-left">
            Invalid email
          </p>
          <BaseLabel
            forAttr="password"
            text="Password"
            class="mt-6 font-medium text-zinc-800 text-left"
          />
          <div
            class="flex items-center px-2 py-2 mt-1 whitespace-nowrap bg-white rounded-md border border-gray-300 shadow-sm text-zinc-800"
          >
            <div class="flex-grow pr-1">
              <BaseInput
                :type="showPassword ? 'text' : 'password'"
                id="password"
                v-model="password"
                placeholder="Enter your password"
                className="w-full bg-transparent border-none focus:outline-none"
                required
              />
            </div>
            <div class="flex-shrink-0 w-8 flex items-center justify-center">
              <button
                type="button"
                @click="togglePasswordVisibility"
                class="focus:outline-none"
              >
                <i
                  :class="{
                    'fas fa-eye': showPassword,
                    'fas fa-eye-slash': !showPassword,
                  }"
                  :title="showPassword ? 'Hide password' : 'Show password'"
                  class="w-4 h-4"
                ></i>
              </button>
            </div>
          </div>
          <div class="flex gap-5 justify-between mt-6 w-full font-medium">
            <CheckBox
              v-model="rememberMe"
              id="rememberMe"
              base-classes="relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none"
              checked-classes="bg-violet-600 border-violet-600 text-white"
              unchecked-classes="bg-white border-violet-600"
              label-classes="text-gray-500 ml-2"
            >
              Remember Me
            </CheckBox>
            <router-link to="forgotpassword" class="text-violet-600"
              >Forgot Password</router-link
            >
          </div>
          <BaseButton
            type="submit"
            :className="buttonClasses"
            :disabled="tempDisable"
          >
            Sign In
          </BaseButton>
          <p
            class="flex gap-1 justify-center px-16 mt-6 text-base leading-6 max-md:px-5"
          >
            <span class="text-gray-500">Don't have an account?</span>
            <router-link to="/signupselect" class="font-medium text-violet-600"
              >Sign up</router-link
            >
          </p>
        </form>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import BaseButton from "@/components/shared/BaseButton.vue";
import axios, { AxiosError } from "axios";
import {
  computed,
  defineComponent,
  ref,
  getCurrentInstance,
  onMounted,
} from "vue";
import { ErrorResponse } from "@/types/interfaces";
import { useAuthStore } from "@/stores/auth";
import { useRouter, useRoute } from "vue-router";
import { PostHog } from "posthog-js";
import { invitedProgram } from "@/types/shared";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import CheckBox from "@/components/shared/CheckBox.vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import { useExpertProgramsStore } from "@/stores/expertPrograms";

export default defineComponent({
  name: "SignIn",
  components: {
    BaseButton,
    BaseLabel,
    CheckBox,
    BaseInput,
  },
  setup() {
    const email = ref("");
    const password = ref("");
    const rememberMe = ref(false);
    const isInvalid = ref(false);
    const showPassword = ref(false);
    const errorMessage = ref<string | null>(null);
    const disable = ref(false);

    const authStore = useAuthStore();
    const programsStore = useExpertProgramsStore();
    const router = useRouter();
    const route = useRoute();
    const invitedToken = route.query.token;
    const posthog = (getCurrentInstance()?.proxy as { $posthog: PostHog })
      ?.$posthog;

    onMounted(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const error = urlParams.get("error");
      if (error) {
        errorMessage.value = error;
        router.replace({ path: router.currentRoute.value.path, query: {} });
      }
    });

    const handleSubmit = () => {
      disable.value = true;
      const validationFailure = validate();
      if (validationFailure.length > 0) {
        errorMessage.value = validationFailure;
        disable.value = false;
      } else {
        const { url, data, config } = getSignInRequestParams(
          email.value,
          password.value
        );

        axios
          .post(url, data, config)
          .then((response) => {
            if (response.data.login_as === "company_user") {
              handleCompanyUserSignIn(response.data.token);
            } else if (response.data.token) {
              handleSignInSuccess(
                response.data.token,
                email.value,
                response.data.invited_program
              );

              goToLanding();
            } else {
              throw new Error("Invalid response from server");
            }
          })
          .catch((error) => {
            errorMessage.value = handleErrorResponse(
              error as AxiosError<ErrorResponse>
            );
          })
          .finally(() => {
            disable.value = false;
          });
      }
    };

    const goToLanding = () => {
      const landingUrl = `${
        process.env.VUE_APP_SIGN_IN_LANDING_URL
      }?token=${encodeURIComponent((invitedToken as string) || "")}`;
      window.open(landingUrl, "_self");
    };

    const handleCompanyUserSignIn = (token: string) => {
      authStore.clearStore();
      programsStore.clearStore();
      const landingUrl = `${
        process.env.VUE_APP_COMPANY_SIGN_IN_LANDING_URL
      }?token=${encodeURIComponent(token)}`;
      window.open(landingUrl, "_self");
    };

    const validateEmail = () => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isInvalid.value = !emailPattern.test(email.value);
    };

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    const validate = () => {
      if (email.value.length < 1) {
        return "Email cannot be blank";
      }

      if (password.value.length < 1) {
        return "Password cannot be blank";
      }

      return "";
    };

    const getSignInRequestParams = (email: string, password: string) => {
      return {
        url: process.env.VUE_APP_SIGN_IN_URL,
        data: {
          user: {
            email: email,
            password: password,
            invited_token: invitedToken,
          },
        },
        config: {
          headers: {
            "Content-Type": "application/json",
          },
        },
      };
    };

    const handleSignInSuccess = (
      token: string,
      email: string,
      invitedProgram: invitedProgram | null
    ) => {
      resetStores();
      if (posthog) {
        posthog.capture("Expert_User_Logged_In", { email: email });
      }
      if (invitedProgram) {
        authStore.setInvitedProgram(invitedProgram);
      }
      authStore.setToken(token);
    };

    const handleErrorResponse = (error: AxiosError<ErrorResponse>) => {
      if (error.response) {
        const errorData = error.response.data;
        if (typeof errorData === "string") {
          return errorData;
        } else if (Array.isArray(errorData.errors)) {
          return errorData.errors.join(", ");
        } else if (typeof errorData.errors === "string") {
          return errorData.errors;
        } else if (typeof errorData.error === "string") {
          return errorData.error;
        }
      }
      return "An unexpected error occurred.";
    };

    const resetStores = () => {
      authStore.clearStore();
      programsStore.clearStore();
    };

    const isDisabled = computed(() => {
      return (
        !(email.value.length > 0 && password.value.length > 0) || disable.value
      );
    });

    const buttonClasses = computed(
      () =>
        `px-4 py-2.5 mt-6 text-base font-semibold leading-6 text-white whitespace-nowrap rounded-md shadow-sm bg-violet-600 max-md:px-5 ${
          isDisabled.value ? "bg-opacity-60" : ""
        }`
    );

    const tempDisable = computed(() => {
      return disable.value;
    });

    return {
      email,
      password,
      rememberMe,
      isInvalid,
      validateEmail,
      handleSubmit,
      showPassword,
      togglePasswordVisibility,
      errorMessage,
      buttonClasses,
      isDisabled,
      tempDisable,
      disable,
    };
  },
});
</script>
