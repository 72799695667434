<template>
  <div
    v-if="errorMessage"
    role="alert"
    class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
    style="max-height: 80px; overflow-y: auto"
  >
    <!-- Icon Container -->
    <div
      class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
    >
      <!-- Custom Image Icon -->
      <img
        src="../../assets/shared/failure.svg"
        alt="Error Icon"
        class="w-4 h-4"
      />
    </div>
    <p>{{ errorMessage }}</p>
  </div>
  <section class="mt-16 max-w-full max-md:mt-10">
    <div class="flex gap-6 flex-row max-lg:flex-col">
      <div class="flex flex-col max-md:ml-0 max-md:w-full">
        <div
          class="flex flex-col px-6 py-8 mx-auto w-full bg-white rounded border border-gray-300 border-solid max-md:px-5 max-md:mt-4"
        >
          <div class="flex items-center">
            <img
              loading="lazy"
              src="../../assets/onboarding/google-calendar-icon.png"
              alt="Google Calendar logo"
              class="object-contain w-16 h-auto"
            />
            <h2 class="text-xl tracking-wide leading-snug text-gray-700">
              Calendar
            </h2>
          </div>
          <div class="flex flex-col mt-6 w-full">
            <h2
              class="text-xl font-semibold tracking-wide leading-snug text-neutral-900"
            >
              Connect Google Calendar
            </h2>
            <p class="mt-2 text-sm tracking-wide leading-5 text-gray-500">
              Connect Google Calendar and set your availability accordingly
            </p>
          </div>
          <div>
            <BaseButton
              type="button"
              class="overflow-hidden self-stretch px-3.5 py-2.5 mt-6 w-full text-sm font-medium leading-none rounded-md shadow-sm"
              :class="{
                'text-indigo-700 bg-indigo-50':
                  authStore.calendarSynced === 'google',
                'text-white bg-violet-600':
                  authStore.calendarSynced !== 'google',
              }"
              :disabled="authStore.calendarSynced === 'google'"
              @click="connectGoogleCalendar"
            >
              <span v-if="authStore.calendarSynced === 'google'"
                >Calendar connected</span
              >
              <span v-else>Connect now</span>
            </BaseButton>
          </div>
        </div>
      </div>

      <div class="flex flex-col max-md:ml-0 max-md:w-full">
        <div
          class="flex flex-col px-6 py-8 mx-auto w-full bg-white rounded border border-gray-300 border-solid max-md:px-5 max-md:mt-4"
        >
          <div class="flex items-center">
            <img
              loading="lazy"
              src="../../assets/onboarding/outlook-calendar-icon.png"
              alt="Google Calendar logo"
              class="object-contain w-12 h-auto"
            />
            <h2 class="text-xl tracking-wide leading-snug text-outlook-blue">
              Outlook
            </h2>
          </div>
          <div class="flex flex-col w-full mt-10">
            <h2
              class="text-xl font-semibold tracking-wide leading-snug text-neutral-900"
            >
              Connect Outlook Calendar
            </h2>
            <p
              class="mt-2 text-sm tracking-wide leading-5 text-gray-500 lg:whitespace-nowrap"
            >
              Connect Outlook Calendar and set your availability accordingly
            </p>
          </div>
          <div>
            <BaseButton
              type="button"
              class="overflow-hidden self-stretch px-3.5 py-2.5 mt-6 w-full text-sm font-medium leading-none rounded-md shadow-sm"
              :class="{
                'text-indigo-700 bg-indigo-50':
                  authStore.calendarSynced === 'outlook',
                'text-white bg-violet-600':
                  authStore.calendarSynced !== 'outlook',
              }"
              :disabled="authStore.calendarSynced === 'outlook'"
              @click="connectOutlookCalendar"
            >
              <span v-if="authStore.calendarSynced === 'outlook'"
                >Calendar connected</span
              >
              <span v-else>Connect now</span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import router from "@/router";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import BaseButton from "@/components/shared/BaseButton.vue";
import { useAuthStore } from "@/stores/auth";

const GET_GOOGLE_OAUTH_URL = gql`
  query {
    googleoauth {
      authorizationUrl
      errors
    }
  }
`;

const GET_OUTLOOK_OAUTH_URL = gql`
  query {
    outlookoauth {
      authorizationUrl
      errors
    }
  }
`;

export default defineComponent({
  name: "SelectCalendarSection",
  components: {
    BaseButton,
  },
  setup() {
    const showModal = ref(true);
    const googleOAuthUrl = ref("");
    const outlookOAuthUrl = ref("");
    const errorMessage = ref("");
    const route = useRoute();
    const authStore = useAuthStore();
    onMounted(() => {
      checkGoogleCalendarSyncFailure();
      checkOutlookCalendarSyncFailure();
    });

    const checkGoogleCalendarSyncFailure = () => {
      const googleCalendarSync = route.query.google_calendar_sync as
        | string
        | undefined;
      if (googleCalendarSync === "false") {
        const message = route.query.details as string | undefined;
        if (message) {
          errorMessage.value = `${message}`;
        }
        clearQueryParams();
      }
    };

    const checkOutlookCalendarSyncFailure = () => {
      const outlookCalendarSync = route.query.outlook_calendar_sync as
        | string
        | undefined;
      if (outlookCalendarSync === "false") {
        const message = route.query.details as string | undefined;
        if (message) {
          errorMessage.value = `${message}`;
        }
        clearQueryParams();
      }
    };

    const clearQueryParams = () => {
      const { fullPath } = route;
      const newUrl = fullPath.split("?")[0];
      router.replace({ path: newUrl });
    };

    const handleModalClose = () => {
      showModal.value = false;
    };

    const connectGoogleCalendar = () => {
      if (calendarSynced()) {
        return;
      }
      fetchGoogleAuthUrl();
    };

    const connectOutlookCalendar = () => {
      if (calendarSynced()) {
        return;
      }
      fetchOutlookAuthUrl();
    };

    const calendarSynced = () => {
      if (
        authStore.calendarSynced === "outlook" ||
        authStore.calendarSynced === "google"
      ) {
        errorMessage.value = "Calendar already connected";
        return true;
      }
    };

    const fetchGoogleAuthUrl = () => {
      errorMessage.value = "";
      const { onResult: queryResult, onError: queryError } = useQuery(
        GET_GOOGLE_OAUTH_URL,
        {
          fetchPolicy: "network-only",
        }
      );

      queryResult((newValue) => {
        if (newValue) {
          if (newValue?.errors) {
            errorMessage.value = "Something went wrong";
          } else {
            if (newValue?.data?.googleoauth?.errors.length > 0) {
              errorMessage.value = "Something went wrong";
            } else if (newValue?.data?.googleoauth?.authorizationUrl) {
              googleOAuthUrl.value =
                newValue?.data?.googleoauth?.authorizationUrl;
              window.location.href = googleOAuthUrl.value;
            }
          }
        }
      });

      queryError((newError) => {
        if (newError) {
          errorMessage.value = "Something went wrong";
        }
      });
    };

    const fetchOutlookAuthUrl = () => {
      errorMessage.value = "";
      const { onResult: queryResult, onError: queryError } = useQuery(
        GET_OUTLOOK_OAUTH_URL,
        {
          fetchPolicy: "network-only",
        }
      );

      queryResult((result) => {
        if (result) {
          if (result?.errors) {
            errorMessage.value = "Something went wrong";
          } else {
            if (result?.data?.outlookoauth?.errors.length > 0) {
              errorMessage.value = "Something went wrong";
            } else if (result?.data?.outlookoauth?.authorizationUrl) {
              outlookOAuthUrl.value =
                result?.data?.outlookoauth?.authorizationUrl;
              window.location.href = outlookOAuthUrl.value;
            }
          }
        }
      });

      queryError((newError) => {
        if (newError) {
          errorMessage.value = "Something went wrong";
        }
      });
    };

    return {
      showModal,
      handleModalClose,
      connectGoogleCalendar,
      errorMessage,
      connectOutlookCalendar,
      authStore,
    };
  },
});
</script>
