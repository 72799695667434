import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col bg-white rounded-md border border-gray-300 shadow-sm w-full max-w-full min-w-[19rem] max-w-[19rem]" }
const _hoisted_2 = { class: "flex items-center h-12 sm:h-14 md:h-16 lg:h-18" }
const _hoisted_3 = { class: "flex-shrink-0 w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 lg:w-18 lg:h-18" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-col flex-1 px-2 sm:px-4 py-2 sm:py-2.5 space-y-2" }
const _hoisted_6 = { class: "flex flex-row items-start sm:items-center sm:space-x-2 sm:space-y-0" }
const _hoisted_7 = { class: "flex-1 text-xs sm:text-sm md:text-base lg:text-lg" }
const _hoisted_8 = { class: "font-medium text-gray-900 truncate text-left break-words" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.logo,
          alt: "Logo",
          class: "object-cover w-full h-full"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.truncatedName), 1)
          ]),
          (!_ctx.isAdded)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitCardData && _ctx.emitCardData(...args))),
                class: "flex-shrink-0 text-primary-purple focus:outline-none"
              }, [
                _createElementVNode("div", { innerHTML: _ctx.CIRCLE_PLUS }, null, 8, _hoisted_9)
              ]))
            : (
              !_ctx.authStore.invitedProgram || _ctx.authStore.invitedProgram.id !== _ctx.id
            )
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "flex-shrink-0 text-gray-600 focus:outline-none",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeCard && _ctx.removeCard(...args)))
                }, [
                  _createElementVNode("div", { innerHTML: _ctx.CLOSE_ICON_SM }, null, 8, _hoisted_10)
                ]))
              : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}