<template>
  <div class="mt-7 w-full max-md:max-w-full px-4">
    <div class="flex gap-5 max-md:flex-col text-left">
      <div class="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
        <div
          class="flex overflow-hidden flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid shadow max-md:mt-7"
        >
          <div class="flex gap-5 items-center p-6 w-full max-md:px-5">
            <div
              class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
            >
              <div v-html="EARNINGS_ICON" class="text-white"></div>
            </div>
            <div
              class="flex flex-col flex-1 shrink self-stretch my-auto basis-6"
            >
              <div class="text-sm font-medium leading-none text-gray-500">
                Balance Available
              </div>
              <div
                class="gap-2 self-start text-2xl font-semibold leading-none text-gray-900"
              >
                ${{ (escrows.availableBalance / 100).toFixed(2) || 0 }} (USD)
              </div>
            </div>
          </div>
          <BaseButton
            type="button"
            @click="withdrawBalance"
            class="flex flex-col justify-center items-start p-4 w-full text-sm font-medium leading-none text-indigo-800 bg-gray-50"
          >
            <span class="self-stretch text-left">Withdraw Balance</span>
          </BaseButton>
        </div>
      </div>

      <div class="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
        <div
          class="flex overflow-hidden grow items-start px-6 pt-6 pb-16 w-full text-gray-900 bg-white rounded-md border border-gray-300 border-solid max-md:px-5 max-md:mt-7"
        >
          <div class="flex flex-col flex-1 shrink w-full basis-0">
            <h3 class="text-base">Upcoming Payment</h3>
            <div
              class="flex items-end mt-1 w-full text-2xl font-semibold leading-none whitespace-nowrap"
            >
              <div class="flex-1 shrink gap-2 w-full">
                ${{ (escrows.pendingBalance / 100).toFixed(2) || 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
        <div
          class="flex overflow-hidden grow items-start px-6 pt-6 pb-16 w-full text-gray-900 bg-white rounded-md border border-gray-300 border-solid max-md:px-5 max-md:mt-7"
        >
          <div class="flex flex-col flex-1 shrink w-full basis-0">
            <h3 class="text-base">All Time Earnings</h3>
            <div
              class="flex items-end mt-1 w-full text-2xl font-semibold leading-none whitespace-nowrap"
            >
              <div class="flex-1 shrink gap-2 w-full">
                ${{ (escrows.totalEarnings / 100).toFixed(2) || 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { EARNINGS_ICON } from "@/assets/svg/expert-overview/svgConstants";
import { Escrow } from "@/types/earnings/types";
import BaseButton from "../shared/BaseButton.vue";
export default defineComponent({
  name: "EarningsOverview",
  components: {
    BaseButton,
  },
  props: {
    escrows: {
      type: Object as PropType<Escrow>,
      required: false,
      default: () => ({
        availableBalance: 0,
        pendingBalance: 0,
        totalEarnings: 0,
      }),
    },
    withdrawBalance: {
      type: Function as PropType<() => void>,
      required: false,
    },
  },
  setup() {
    return {
      EARNINGS_ICON,
    };
  },
});
</script>
